import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import CTA from "../components/cta"

import { Link } from "gatsby"
import GoCardlessLogo from "../images/GoCardless_Wordmark_Regular_Neg_Dawn.svg"
import JodyProfile from "../images/jody-clark.jpeg"
import GCSplashImage from "../images/posts/gocardless-case-study-og.png"

import logo from "../images/logo-only.svg"


const Platform = () => {
  return (
    <Layout variant="case-study">
      <SEO
        title="GoCardless increases pipeline efficiency by 40% with Tactic"
        description="79% increase in average contract value. 40% increase in pipeline efficiency. 2.7m more data points in CRM."
        image={GCSplashImage}
      />

      <div className="container mx-auto py-8 md:py-16 px-6">
        <section className="container text-white my-12">
          <div className="px-4 lg:px-0 lg:w-3/4">
            <img src={GoCardlessLogo} className="mb-12 w-72" />
            <h1 className="text-5xl lg:text-6xl leading-tight mb-8 font-semibold">
              GoCardless increases pipeline efficiency by 40% with Tactic
            </h1>
            <div className="flex flex-col md:flex-row gap-4 md:gap-24">
              <div>
                <div className="font-sans text-dark-grey text-sm">
                  Headquarters
                </div>
                <div>London, United Kingdom</div>
              </div>
              <div>
                <div className="font-sans text-dark-grey text-sm">Founded</div>
                <div>2011</div>
              </div>
              <div>
                <div className="font-sans text-dark-grey text-sm">
                  Company Size
                </div>
                <div>800+</div>
              </div>
              <div>
                <div className="font-sans text-dark-grey text-sm">Industry</div>
                <div>Payments and fintech</div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="container mx-auto flex flex-col lg:flex-row gap-4 px-6">
        <div className="rounded-lg p-12 flex-1 bg-gradient-to-br from-gray-700 to-gray-900 shadow-xl text-white">
          <h3 className="text-white text-8xl">+40%</h3>increase in pipeline
          efficiency
        </div>
        <div className="rounded-lg p-12 flex-1 bg-gradient-to-br from-gray-700 to-gray-900 shadow-xl text-white">
          <h3 className="text-white text-8xl">+79%</h3>increase in ACV
        </div>
        <div className="rounded-lg p-12 flex-1 bg-gradient-to-br from-gray-700 to-gray-900 shadow-xl text-white">
          <h3 className="text-white text-8xl">+2.7m</h3>increase in relevant CRM
          data points efficiency
        </div>
      </div>

      <section className="bg-white mb-24 -mt-24 pt-36">
        <div className="max-w-3xl mx-auto py-8 md:py-16 px-6 prose prose-xl text-gray-600">
          <p>
            GoCardless is a global leader in direct bank payment solutions,
            making it easy to collect both recurring and one-off payments
            directly from customers' bank accounts through direct debit and open
            banking.
          </p>
          <p>
            The GoCardless global payments network and technology platform take
            the pain out of getting paid for 70,000 businesses worldwide, from
            multinational corporations to small businesses.
          </p>
          <p>
            Each year GoCardless processes over US$30 billion of payments across
            more than 30 countries. GoCardless is headquartered in the UK, with
            additional offices in Australia, France, Germany and the United
            States.
          </p>
          <p>
            GoCardless is accelerating its open banking strategy. Over the past
            18 months it has launched its open banking-enabled Instant Bank Pay
            feature across multiple markets, as well as Verified Mandates, which
            combines AIS with direct debit to prevent fraud.
          </p>
          <p>
            And, explains Jody Clark, GoCardless' Global VP of Sales
            Development, a clean and expanding list of high fit accounts
            globally is key to achieving this goal:
          </p>
          <blockquote className="text-black not-italic text-2xl leading-10">
            “Great data enables us to direct our sales and marketing efforts
            towards the right accounts at the right time, maximizing efficiency
            in our investments."
          </blockquote>
          <p>
            "By targeting companies with key ICP characteristics instead of
            traditional firmographic factors like industry, it enables us to
            focus on companies that will be successful using GoCardless."
          </p>

          <h2>Ideal customer accounts, at a global scale</h2>
          <p>
            GoCardless turned to Tactic to build{" "}
            <Link to="/use-case/smarter-target-accounts/">
              target account lists
            </Link>{" "}
            that scores and ranks companies based on 30+ completely customized
            characteristics. Characteristics such as companies with a recurring
            revenue model, self-serve portals, or recent{" "}
            <Link to="/use-case/digital-transformation/">
              digital transformation
            </Link>{" "}
            initiatives.
          </p>
          <p>
            “Standard company attributes like industry and revenue do not
            uncover the real size of our opportunity at GoCardless. We needed
            customized attributes and triggers unique to our proposition.”, said
            Jody.
          </p>
          <blockquote className="text-black not-italic text-2xl leading-10">
            “Without Tactic, we lose time from sales teams manually sourcing
            this data, lose marketing budget from suboptimal targeting, and
            create operational headaches managing multiple data vendors."
          </blockquote>
          <p>
            “In a matter of weeks, we were able to specify 30+ custom
            characteristics for each of our{" "}
            <Link to="/use-case/international-expansion/">
              five global markets
            </Link>
            , then we cleaned and enriched over 100,000 accounts in our CRM,”
            explained Jody.
          </p>
          <blockquote className="text-black not-italic text-2xl leading-10">
            “We've seen our pipeline efficiency increase by 40% in Q2 2022
            alone, thanks to working with Tactic and the customized data it
            delivers.”
          </blockquote>
          <p>
            On the back of such strong results, GoCardless has enabled all its
            Sales Development Representatives and Account Executives to use
            Tactic to transform the way GoCardless{" "}
            <Link to="/sales/">sales teams</Link> personalise outreach at scale.
          </p>
          <p>
            Tactic's research reports show salespeople the most relevant and
            recent triggers for outreach. Managers can configure the reports to
            guide their teams.
          </p>
          <p>
            For example, in the UK market, a company launching a new digital
            product is a key trigger. Tactic crunches the internet to find these
            specific triggers, so that reps can see this information directly in
            Salesforce, replacing hours of manual Googling and data entry.
          </p>

          <div className="bg-medium-grey lg:-mx-48 my-24 p-8 lg:p-24 rounded-lg flex flex-col lg:flex-row gap-4 lg:gap-24">
            <div>
              <img src={JodyProfile} className="rounded-full" />
            </div>
            <div>
              <div className="text-black text-xl lg:text-3xl leading-10 mb-12">
                "Tactic is truly an extension of our data capability, and
                enables us to deliver high quality data to sales, marketing,
                customer success, partnerships, and more with a small team."
              </div>
              <div>
                &mdash; Jody Clark, Global Vice President of Sales Development
              </div>
            </div>
          </div>

          <h2>Data automation increases pipeline efficiency</h2>
          <p>
            In the first quarter of implementing Tactic, GoCardless saw a 79%
            increase in average contract value. They enriched 100,000+ accounts
            in Salesforce in a more cost effective and efficient way than
            managing multiple data vendors and data pipelines, adding 2.7m new,
            clean and relevant attributes into their CRM.
          </p>
          <p>
            Building upon the successful target accounts use case, GoCardless
            expanded its use of Tactic's data automation across their
            go-to-market strategy.
          </p>
          <p>
            <Link to="/solutions#marketing">ABM</Link> and major account teams now
            use Tactic to get deep insight into subscription revenue at large
            Fortune 100 companies.
          </p>
          <p>
            <Link to="/solutions#marketing">Demand generation teams</Link> build
            highly targeted audiences in hours, not weeks.{" "}
            <Link to="/solutions#partnerships">Partnership teams</Link> are able
            to prioritize their focus to accelerate new partner acquisition.
            <Link to="/solutions#customer-success">
              Customer success teams
            </Link>{" "}
            get new signals to complement product usage, driving net dollar
            retention and Account Executives can finally spend their time
            prospecting, not researching.
          </p>
          <h2>A partnership to be proud of</h2>
          <p>
            Jody believes GoCardless' experience shows the true power of putting
            data at the centre of go-to-market strategy.
          </p>
          <p>
            Reflecting on the success of GoCardless with Tactic, Jody highlights
            the close collaboration between the two companies.
          </p>
          <blockquote className="text-black not-italic text-2xl leading-10">
            “With Tactic, GoCardless has a true partnership. We work closely
            together to understand future challenges and opportunities, then
            solve them with data. ”
          </blockquote>
          <p>
            "Tactic is truly an extension of our data capability, and enables us
            to deliver high quality data to sales, marketing, customer success,
            partnerships, and more with a small team. We get constant input to
            Tactic's product roadmap in order to grow together.“
          </p>
          <p>
            Looking to the future, GoCardless is excited about partnering with
            Tactic as it evolves its go-to-market data to increase revenue
            efficiency.
          </p>
          <p>
            Jody concludes, “Our sales engagement provider performed an industry
            wide comparison, and GoCardless' pipeline generation metrics came
            out on top of all companies. This proves that data-driven
            personalisation at scale works at a fantastic level, and Tactic is
            the key enabler of that success.”
          </p>
        </div>
        <div className="container mx-auto">
          <div className="flex items-center gap-6">
            <div className="h-px bg-medium-grey flex-1"></div>
            <img src={logo} className="h-12 mx-auto my-12" />
            <div className="h-px bg-medium-grey flex-1"></div>
          </div>
        </div>
      </section>
      <div id="cta">
        <CTA />
      </div>
    </Layout>
  )
}

export default Platform
